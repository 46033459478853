import React from "react";
import { Link } from "gatsby";
const data = [
  {
    id: 1,
    text: "home",
    url: "/",
  },

  {
    id: 2,
    text: "contact",
    url: "/contact/",
  },
  {
    id: 3,
    text: "resume",
    url: "/resume",
  },
];

const tempLinks = data.map(link => {
  return (
    <li key={link.id}>
      <Link to={link.url}>{link.text}</Link>
    </li>
  );
});

const Links = ({ styleClass }) => {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {tempLinks}
    </ul>
  );
};

export default Links;
