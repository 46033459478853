import React from "react";
import { FaLinkedin, FaTwitterSquare, FaCodepen } from "react-icons/fa";

const data = [
  {
    id: 2,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/miguel-ramirez-675682121",
  },
  {
    id: 4,
    icon: <FaCodepen className="social-icon"></FaCodepen>,
    url: "https://codepen.io/miguelaram27",
  },
  {
    id: 5,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://twitter.com/LocalhostD3v",
  },
];
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  );
});

const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  );
};
export default SocialLinks;
